@tailwind base;
@tailwind components;
@tailwind utilities;

.charon-background-image {
  background-image: url("../assets/Charon.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 20%;
}
