:root {
  --main-color: #ab8847;
  --second-color: #dccc5b;
}

body {
  background-color: #0d0f23;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
}

.credit {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 200px;
  position: absolute;
  bottom: 25px;
  right: 40px;
  font-size: 1.1rem;
  color: var(--main-color);
}

.credit_title {
  color: var(--second-color);
}
.copyright {
  color: var(--second-color);
  font-size: 0.9rem;
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.with-conic-gradient {
  color: var(--second-color);
  border-radius: 0.5rem;
  width: 300px;
  height: 60px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  box-shadow: 0 0 6px 0 rgba(96, 175, 212, 0.5);
  border: solid 3px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(60deg, magenta, yellow);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #0d0e21 inset;
}

.logo {
  font-size: 2.5rem;
  position: absolute;
  top: 10px;
  left: 20px;
}

.logo > a > img {
  width: 48px;
  position: absolute;
  top: 5px;
}

.spirit {
  margin-left: 60px;
}

.buttonMetamask {
  display: flex;
  margin-bottom: 40px;
}

#metamask-connected {
  font-size: 20px;
}

@media (max-width: 1000px) {
  .credit {
    position: initial;
    bottom: 25px;
    padding-right: none;
  }
  .buttonMetamask {
    margin-bottom: 0px;
  }
}
@media (max-width: 640px) {
  .buttonMetamask {
    margin-left: 25px;
    width: 330px;
  }
  .hide {
    display: none;
  }
  .credit {
    width: 100%;
    font-size: 14px;
    margin: 0 10px 20px 0;
    visibility: hidden;
  }
  .title_One {
    position: absolute;
    font-weight: 600;
    left: 10px;
    bottom: 70px;
  }
  .phrase_One {
    position: absolute;
    bottom: 50px;
    left: 10px;
  }
  .phrase_Two {
    position: absolute;
    bottom: 30px;
    left: 10px;
  }
  .title_Two {
    font-weight: 600;
  }
  .copyright {
    bottom: 0;
    margin-left: 5px;
    padding: 5px 5px;
    font-size: 0.8rem;
    animation: fade 2s infinite;
    border-left: 2px solid var(--second-color);
    border-top: 2px solid var(--second-color);
    border-right: 2px solid var(--second-color);
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
